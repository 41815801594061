<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.nom"
            :error-messages="nomErrors"
            :label="$t('nom')"
            @input="$v.item.nom.$touch()"
            @blur="$v.item.nom.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-select
            v-model="item.site"
            :items="siteSelectItems"
            :error-messages="siteErrors"
            :no-data-text="$t('No results')"
            :label="$t('Site')"
            item-text="nom"
            item-value="@id"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.salle"
            :error-messages="salleErrors"
            :label="$t('salle')"
            @input="$v.item.salle.$touch()"
            @blur="$v.item.salle.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.etage"
            :error-messages="etageErrors"
            :label="$t('etage')"
            @input="$v.item.etage.$touch()"
            @blur="$v.item.etage.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.batiment"
            :error-messages="batimentErrors"
            :label="$t('batiment')"
            @input="$v.item.batiment.$touch()"
            @blur="$v.item.batiment.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.visible"
            :error-messages="visibleErrors"
            :label="$t('visible')"
            @input="$v.item.visible.$touch()"
            @blur="$v.item.visible.$touch()"
          />
        </v-col>
      </v-row>
      
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'LocalisationForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
      this.siteGetSelectItems();
  },
  data() {
    return {
        nom: null,
        site: null,
        salle: null,
        etage: null,
        batiment: null,
        visible: null,
    };
  },
  computed: {
      ...mapFields('site', {
        siteSelectItems: 'selectItems'
      }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, 'nom') && errors.push(this.violations.nom);


      return errors;
    },
    siteErrors() {
      const errors = [];

      if (!this.$v.item.site.$dirty) return errors;

      has(this.violations, 'site') && errors.push(this.violations.site);


      return errors;
    },
    salleErrors() {
      const errors = [];

      if (!this.$v.item.salle.$dirty) return errors;

      has(this.violations, 'salle') && errors.push(this.violations.salle);


      return errors;
    },
    etageErrors() {
      const errors = [];

      if (!this.$v.item.etage.$dirty) return errors;

      has(this.violations, 'etage') && errors.push(this.violations.etage);


      return errors;
    },
    batimentErrors() {
      const errors = [];

      if (!this.$v.item.batiment.$dirty) return errors;

      has(this.violations, 'batiment') && errors.push(this.violations.batiment);


      return errors;
    },
    visibleErrors() {
      const errors = [];

      if (!this.$v.item.visible.$dirty) return errors;

      has(this.violations, 'visible') && errors.push(this.violations.visible);


      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
      ...mapActions({
        siteGetSelectItems: 'site/fetchSelectItems'
      }),
  },
  validations: {
    item: {
      nom: {
      },
      site: {
      },
      salle: {
      },
      etage: {
      },
      batiment: {
      },
      visible: {
      },
    }
  }
};
</script>
